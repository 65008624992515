// src/components/Services/ServicesHeader.tsx
import React from 'react';
import { motion } from 'framer-motion';

const ServicesHeader: React.FC = () => {
  return (
    <header className="relative h-96 flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: 'url(/images/drilling.png)' }}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-center"
      >
        <h1 className="text-5xl md:text-7xl font-bold text-white">
          Serviços de <br className="hidden md:block" /> qualidade
        </h1>
      </motion.div>
    </header>
  );
};

export default ServicesHeader;
