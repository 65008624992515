// src/pages/Sobre.tsx
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CallToAction from '../components/Services/CallToAction';
import MetricCard from '../components/Sobre/MetricCard';
import OfficeLocation from '../components/Sobre/OfficeLocation';
import SobreHeader from '../components/Sobre/SobreHeader';
import { motion } from 'framer-motion';

const Sobre: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow mt-16 bg-[#292A2E] text-white">
        {/* Seção de Título */}
        <SobreHeader />
        {/* Seção de Descrição */}
        <div className="container mx-auto px-4 py-12 max-w-4xl">
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <p className="text-lg">
              Fundada em 2023, na cidade de Belo Horizonte, Minas Gerais, a RKE Sondagem e Perfurações LTDA trabalha com confiança em sua visão de se tornar referência no setor de exploração mineral e ser reconhecida como sinônimo de excelência em sondagem e perfuração.
            </p>
            <p className="text-lg">
              Nossa missão sempre foi clara: oferecer serviços de alta qualidade, com precisão e responsabilidade, respeitando o meio ambiente e as comunidades locais. Acreditamos que a exploração mineral, quando feita de forma ética e sustentável, pode trazer benefícios duradouros para todos.
            </p>
            <p className="text-lg">
              Estamos focados no futuro e investimos constantemente em tecnologia e treinamento, garantindo que continuemos na vanguarda da indústria e prontos para os desafios do amanhã.
            </p>
            <p className="text-lg">
              Junte-se a nós nesta jornada e descubra o verdadeiro valor que se esconde nas profundezas da terra.
            </p>
          </motion.div>
        </div>

        {/* Seção de Conquistas */}
        <div className="bg-[#1E1F25] py-12">
          <div className="container mx-auto px-4">
            <motion.div
              className="text-center mb-12"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold text-[#FDD963]">Nossas conquistas</h3>
              <h4 className="mt-2 text-2xl font-bold text-white">
                Nossos Indicadores Revelam Nossa Trajetória de Sucesso.
              </h4>
            </motion.div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
              <MetricCard value="15+" description="Projetos concluídos" />
              <MetricCard value="R$ 60+ MM" description="Retorno para clientes" />
              <MetricCard value="16" description="Jazidas descobertas" />
              <MetricCard value="6" description="Laboratórios" />
            </div>
          </div>
        </div>

        {/* Seção do Escritório */}
        <div className="container mx-auto px-4 py-12 max-w-4xl">
          <motion.div
            className="text-center mb-8"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-3xl font-bold text-[#FDD963]">Estamos em <br className="hidden md:block" /> Belo Horizonte, MG.</h2>
          </motion.div>
          <OfficeLocation />
        </div>

        {/* Seção de Call to Action */}
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Sobre;
