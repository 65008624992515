// src/pages/Portfolio.tsx
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CallToAction from '../components/Services/CallToAction';
import PortfolioHeader from '../components/Portfolio/PortfolioHeader';
import PortfolioItem from '../components/Portfolio/PortfolioItem';
import { motion } from 'framer-motion';

const Portfolio: React.FC = () => {
  const portfolioData = [
    {
      title: 'Mineração Santa Joana: Sondagem',
      year: '2023',
      description: 'Projeto de sondagem da Mineração Santa Joana em andamento. Em breve mais detalhes.',
      imageSrc: 'https://framerusercontent.com/images/7MP5JF2wPvMAcdChwv0JMBGE390.jpg',
    },
    {
      title: 'MAANAIN Mineração: Sondagem',
      year: '2023',
      description: 'Projeto de sondagem na MAANAIN Mineração em andamento. Em breve mais detalhes.',
      imageSrc: 'https://framerusercontent.com/images/0e4coeSjhyEquRUrSx1J1nHAw.jpg',
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow mt-16 bg-[#292A2E]">
        <PortfolioHeader />
        <div className="container mx-auto px-4 py-12 flex flex-col items-center max-w-[800px]">
          {portfolioData.map((item, index) => (
            <PortfolioItem
              key={index}
              title={item.title}
              year={item.year}
              description={item.description}
              imageSrc={item.imageSrc}
            />
          ))}
        </div>
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Portfolio;
