// src/components/Services/Services.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon, MapPinIcon, BeakerIcon } from '@heroicons/react/24/outline'; // Importe ícones desejados

interface Service {
  title: string;
  description: string;
}

const services = [
  {
    title: 'Sondagem & Exploração',
    description: 'Especialistas em identificar e avaliar depósitos minerais através de sondagem e exploração meticulosa.',
    icon: <MagnifyingGlassIcon className="w-12 h-12 text-purple-600" />, // Ícone dinâmico
  },
  {
    title: 'Perfuração',
    description: 'Perfuração de precisão para acessar e coletar amostras do subsolo, garantindo resultados confiáveis.',
    icon: <MapPinIcon className="w-12 h-12 text-purple-600" />, // Ícone dinâmico
  },
  {
    title: 'Relatórios Físicos e Químicos',
    description: 'Análises detalhadas das propriedades físicas e químicas das amostras, fundamentais para decisões de mineração.',
    icon: <BeakerIcon className="w-12 h-12 text-purple-600" />, // Ícone dinâmico
  },
];

const Services: React.FC = () => {
  return (
    <section id="servicos" className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-extrabold text-[#CFB354] mb-8"
        >
          Descubra como nossos serviços<br />podem alavancar<br />suas operações.
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-[#CFB354] p-10 rounded-lg shadow-lg text-left"
              whileHover={{ scale: 1.05, boxShadow: '0px 10px 30px rgba(0,0,0,0.1)' }}
            >
              {/* Ícone dinâmico via array */}
              <div className="bg-white rounded-xl p-4 mb-6 flex items-center justify-start w-20 h-20">
                {service.icon} {/* Ícone dinâmico */}
              </div>
              <h3 className="text-xl font-semibold text-[#19154E] mb-4">{service.title}</h3>
              <p className="text-gray-700 mb-6">{service.description}</p>
              <Link
                to="/servicos"
                className="text-purple-600 font-semibold hover:underline"
              >
                Mais detalhes
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
