// src/pages/Contato.tsx
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { motion } from 'framer-motion';
import { sendEmail } from '../utils/emailjs';
import { useRef, useState, useEffect } from 'react'; // Certifique-se de incluir useEffect aqui

const Contato: React.FC = () => {

    const form = useRef<HTMLFormElement>(null);
    const [isSending, setIsSending] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [formStartTime, setFormStartTime] = useState<number>(Date.now());

    useEffect(() => {
        setFormStartTime(Date.now());
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsSending(true); // Ativa o estado de envio

        const timeTaken = Date.now() - formStartTime;

        if (timeTaken < 3000) { // Se o formulário for enviado em menos de 3 segundos
            setMessage('Preencha o formulário corretamente.');
            setError(true);
            setIsSending(false); // Desativa o estado de envio
            return;
        }

        if (form.current) {
            const honeypot = form.current.querySelector<HTMLInputElement>('input[name="honeypot"]');
            if (honeypot?.value) {
                setMessage('Algo deu errado... Use o botão do Whatsapp para contato.'); // Mensagem para bots
                setError(true);
                setIsSending(false); // Desativa o estado de envio
                return;
            }

            // Processar o envio normal
            try {
                await sendEmail(form.current);
                setMessage('Mensagem enviada com sucesso! Em breve entraremos em contato.');
                setError(false);
                form.current.reset();
            } catch (error) {
                console.error('Erro ao enviar mensagem', error);
                setMessage('Erro ao enviar mensagem. Tente novamente.');
                setError(true);
            } finally {
                setIsSending(false); // Desativa o estado de envio
            }
        }
    };


    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow mt-16 bg-[#292A2E] text-white">
                <div className="flex justify-start items-start py-4 md:py-8 px-4 md:px-8">
                    <div className="w-full mx-auto md:w-4/5 lg:w-4/5">
                        {/* Linha 1: Parágrafo */}
                        <motion.p
                            className="text-lg md:text-xl font-semibold text-[#FDD963]"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            ENTRE EM CONTATO
                        </motion.p>

                        {/* Linha 2: Cabeçalho */}
                        <motion.h1
                            className="text-3xl md:text-5xl font-bold mt-2"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            Nós podemos<br />ajudar
                        </motion.h1>

                        {/* Linha 3: Duas Colunas */}
                        <div className="mt-8 flex flex-col md:flex-row gap-8">
                            {/* Coluna 3.1: Mapa */}
                            <motion.div
                                className="w-full md:w-3/5 h-[50vh]"
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6 }}
                                viewport={{ once: true }}
                            >
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.3021967174764!2d-43.96189210919456!3d-19.95378953241489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa697777a2018f7%3A0x4ae6ff1a84e0326c!2sAv.%20Raja%20Gab%C3%A1glia%2C%202000%20-%20Estoril%2C%20Belo%20Horizonte%20-%20MG%2C%2030494-170!5e0!3m2!1spt-BR!2sbr!4v1727960935608!5m2!1spt-BR!2sbr"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0, borderRadius: '0.5rem' }}
                                    allowFullScreen
                                    loading="lazy"
                                    title="Localização da RKE Sondagem e Perfurações LTDA"
                                ></iframe>
                            </motion.div>

                            {/* Coluna 3.2: Informações de Contato */}
                            <motion.div
                                className="w-full md:w-2/5 flex flex-col gap-6"
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.3 }}
                                viewport={{ once: true }}
                            >
                                {/* Texto de Apresentação */}
                                <div>
                                    <p className="text-lg">
                                        Estamos aqui para ajudar sua empresa a encontrar valor.<br />Entre em contato para saber como podemos ajudar.
                                    </p>
                                </div>

                                {/* Endereço */}
                                <div>
                                    <h3 className="text-xl font-semibold text-[#FDD963]">Endereço</h3>
                                    <p className="mt-2">
                                        Av. Raja Gabaglia 2000<br />
                                        Salas 703 a 710 - Torre 1<br />
                                        Bairro Estoril – CEP: 30494-170<br />
                                        Belo Horizonte / Minas Gerais<br />
                                        Brasil
                                    </p>
                                </div>

                                {/* Contatos */}
                                <div className="space-y-4">
                                    {/* Contato 1 */}
                                    <a href="https://wa.me/5538999932930" className="flex items-center gap-2 hover:text-[#CFB354] transition-colors">
                                        {/* Ícone de Telefone */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            focusable="false"
                                            color="rgb(37, 211, 102)"
                                            className="w-[30px] h-[30px] text-[#FDD963]"
                                            style={{
                                                userSelect: 'none',
                                                display: 'inline-block',
                                                fill: 'rgb(37, 211, 102)',
                                                color: 'rgb(37, 211, 102)',
                                                flexShrink: 0,
                                            }}
                                        >
                                            <g color="rgb(37, 211, 102)">
                                                <path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.61-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z" />
                                            </g>
                                        </svg>

                                        <span>Rodrigo Lima +55 38 99993-2930</span>
                                    </a>

                                    {/* Contato 2 */}
                                    <a href="https://wa.me/5531991956007" className="flex items-center gap-2 hover:text-[#CFB354] transition-colors">
                                        {/* Ícone de Telefone */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            focusable="false"
                                            color="rgb(37, 211, 102)"
                                            className="w-[30px] h-[30px] text-[#FDD963]"
                                            style={{
                                                userSelect: 'none',
                                                display: 'inline-block',
                                                fill: 'rgb(37, 211, 102)',
                                                color: 'rgb(37, 211, 102)',
                                                flexShrink: 0,
                                            }}
                                        >
                                            <g color="rgb(37, 211, 102)">
                                                <path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.61-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z" />
                                            </g>
                                        </svg>
                                        <span>Luiz Eduardo +55 31 99195-6007</span>
                                    </a>

                                    {/* E-mail */}
                                    <a href="mailto:contato@rkesondagem.com.br" className="flex items-center gap-2 hover:text-[#CFB354] transition-colors">
                                        {/* Ícone de E-mail */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            focusable="false"
                                            color="rgb(102, 102, 255)"
                                            style={{
                                                userSelect: 'none',
                                                width: '30px', // Define a largura para 30px
                                                height: '30px', // Define a altura para 30px
                                                display: 'inline-block',
                                                fill: 'rgb(102, 102, 255)',
                                                color: 'rgb(102, 102, 255)',
                                                flexShrink: 0,
                                            }}
                                        >
                                            <g color="rgb(102, 102, 255)">
                                                <polyline
                                                    points="224 56 128 144 32 56"
                                                    fill="none"
                                                    stroke="rgb(255, 255, 255)"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={24}
                                                />
                                                <path
                                                    d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a0,0,0,0,1,0,0Z"
                                                    fill="none"
                                                    stroke="rgb(255, 255, 255)"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={24}
                                                />
                                                <line
                                                    x1="110.5"
                                                    y1="128"
                                                    x2="34.5"
                                                    y2="197.7"
                                                    fill="none"
                                                    stroke="rgb(255, 255, 255)"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={24}
                                                />
                                                <line
                                                    x1="221.5"
                                                    y1="197.7"
                                                    x2="145.5"
                                                    y2="128"
                                                    fill="none"
                                                    stroke="rgb(255, 255, 255)"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={24}
                                                />
                                            </g>
                                        </svg>

                                        <span>contato@rkesondagem.com.br</span>
                                    </a>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
                {/* Formulário de Contato */}
                <section className="px-4 md:px-8 py-12 bg-white">
                    <motion.div
                        className="text-center mb-8"
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        viewport={{ once: true }}
                    >
                        <h2 className="text-4xl font-bold text-[#292A2E]">
                            Ou envie uma mensagem<br />pelo formulário
                        </h2>
                    </motion.div>
                    <motion.div
                        className="max-w-xl mx-auto"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        viewport={{ once: true }}
                    >
                        <form ref={form} onSubmit={handleSubmit} className="space-y-6">
                            {/* Campo Honeypot (oculto) */}
                            <input type="text" name="honeypot" style={{ display: 'none' }} />

                            {/* Linha 1: Nome (ocupa toda a linha) */}
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                    required
                                    className="w-full px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
                                />
                            </div>

                            {/* Linha 2: Email e Telefone (cada um ocupa metade da linha) */}
                            <div className="flex flex-col md:flex-row gap-4">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    required
                                    className="w-full md:w-1/2 px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
                                />
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Telefone"
                                    required
                                    className="w-full md:w-1/2 px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
                                />
                            </div>

                            {/* Linha 3: Mensagem */}
                            <div>
                                <textarea
                                    name="message"
                                    placeholder="Mensagem"
                                    required
                                    className="w-full px-4 py-2 h-32 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963] resize-none"
                                ></textarea>
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    disabled={isSending}
                                    className="px-6 py-2 rounded-md bg-[#FDD963] text-black font-semibold hover:bg-[#CFB354] transition-colors"
                                >
                                    {isSending ? 'Enviando...' : 'Enviar'}
                                </button>
                            </div>

                            {/* Mensagem de Sucesso ou Erro */}
                            {message && (
                                <p className={`text-center mt-4 ${error ? 'text-red-500' : 'text-green-500'}`}>
                                    {message}
                                </p>
                            )}
                        </form>


                    </motion.div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default Contato;
