// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Servicos from './pages/Servicos';
import Portfolio from './pages/Portfolio';
import Sobre from './pages/Sobre';
import Contato from './pages/Contato';
import './styles.css';


const App: React.FC = () => {
  return (
    <div className="bg-[#292A2E] min-h-screen">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Futuras rotas para Serviços e Portfolio */}
          <Route path="/servicos" element={<Servicos />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/sobre" element={<Sobre />} />
          {/* Futuras rotas para Portfolio e outras páginas */}
          <Route path="/contato" element={<Contato />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
