// src/components/Footer/Footer.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Ícones Sociais */}
        <div className="flex justify-center space-x-6 mb-4">
          <motion.a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2, color: '#b59a5a' }}
            className="text-gray-300 hover:text-white"
          >
            <FaInstagram size={24} />
          </motion.a>
          <motion.a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2, color: '#b59a5a' }}
            className="text-gray-300 hover:text-white"
          >
            <FaFacebook size={24} />
          </motion.a>
          <motion.a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2, color: '#b59a5a' }}
            className="text-gray-300 hover:text-white"
          >
            <FaLinkedin size={24}/>
          </motion.a>
        </div>
        {/* Informações da Empresa */}
        <div className="text-center mb-4">
          <p className="text-sm">2024 © RKE Sondagem e Perfurações LTDA</p>
          <p className="text-sm">CNPJ: 51.820.244/0001-51</p>
          <p className="text-sm">Telefones: (31)99195-6007 | (38)99993-2930</p>
          <p className="text-sm">
            E-mail: <a href="mailto:contato@rkesondagem.com.br" className="text-[#CFB354] hover:underline">contato@rkesondagem.com.br</a>
          </p>
        </div>
        {/* Link para Tecnologia Utilizada */}
        <div className="text-center">
          <p className="text-sm">
            <a
              href="https://www.horizonteinteligencia.com.br"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline"
            >
              Feito com Criatividade - Horizonte Tecnologia
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
