// src/components/Services/CallToAction.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const CallToAction: React.FC = () => {
  return (
    <motion.div
      className="bg-white py-12 text-center"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <h2 className="text-4xl md:text-5xl font-bold text-[#001122] mb-6">
        Vamos trabalhar juntos <br className="hidden md:block" /> em seu próximo projeto
      </h2>
      <Link to="/contato">
        <button className="bg-[#001122] text-white px-6 py-3 rounded-md hover:bg-[#CFB354] transition">
          Entre em contato
        </button>
      </Link>
    </motion.div>
  );
};

export default CallToAction;
