// src/components/Sobre/OfficeLocation.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { MapPinIcon } from '@heroicons/react/24/solid';

const OfficeLocation: React.FC = () => {
    return (
      <motion.div
        className="bg-[#CFB354] rounded-lg shadow-lg p-6 flex flex-col items-center text-center max-w-[600px] mx-auto"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <MapPinIcon className="h-8 w-8 text-purple-600" />
        <h3 className="mt-4 text-2xl font-semibold text-[#19154E]">Belo Horizonte, MG</h3>
        <p className="mt-2 text-[#19154E]">
          Av. Raja Gabaglia 2000, Sala 703 a 710 - Torre 1 - Estoril - Belo Horizonte/MG
        </p>
        <a
          href="https://maps.app.goo.gl/HCBpFSYZEGC14BPH7"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 text-purple-600"
        >
          Veja no Google Maps
        </a>
      </motion.div>
    );
  };
  

export default OfficeLocation;
