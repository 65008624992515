// src/components/Portfolio/PortfolioItem.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface PortfolioItemProps {
  title: string;
  year: string;
  description: string;
  imageSrc: string;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ title, year, description, imageSrc }) => {
  return (
    <motion.div
      className="w-full mb-12"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {/* Linha 1: Imagem de Fundo */}
      <div
        className="w-full h-64 md:h-96 bg-cover bg-center rounded-md shadow-lg"
        style={{ backgroundImage: `url(${imageSrc})` }}
        aria-label={title}
      ></div>

      {/* Linha 2: Título e Ano */}
      <div className="mt-4">
        <h2 className="text-2xl md:text-3xl font-semibold text-[#FDD963]">{title}</h2>
        <p className="text-gray-300 text-sm md:text-base">{year}</p>
      </div>

      {/* Linha 3: Descrição */}
      <div className="mt-2">
        <p className="text-white text-sm md:text-base">{description}</p>
      </div>
    </motion.div>
  );
};

export default PortfolioItem;
