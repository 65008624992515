// src/components/Portfolio/Portfolio.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface Project {
  title: string;
  imageUrl: string;
}

const projects: Project[] = [
  {
    title: 'Mineração Santa Joana',
    imageUrl: '/images/portfolio1.png',
  },
  {
    title: 'MAANAIN Mineração',
    imageUrl: '/images/portfolio2.png',
  },
  // Adicione mais projetos conforme necessário
];

const Portfolio: React.FC = () => {
  return (
    <section id="portfolio" className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-extrabold text-[#CFB354] mb-8"
        >
          Confira alguns de nossos<br />Projetos de Sucesso
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden h-[504px] md:w-[590px] md:h-[590px] flex flex-col"
              whileHover={{ scale: 1.05, boxShadow: '0px 10px 30px rgba(0,0,0,0.1)' }}
            >
              {/* Imagem no Card */}
              <div className="h-[75%]">
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="w-full h-full object-cover" // A imagem agora ocupa 100% da altura do contêiner
                />
              </div>
              {/* Parte Inferior do Card */}
              <div className="bg-[#CFB354] p-4 flex flex-col text-left h-[25%]">
                <h3 className="text-xl font-semibold text-[#19154E] mb-2">{project.title}</h3>
                <Link
                  to="/portfolio"
                  className="inline-flex bg-gray-800 text-white font-semibold py-[15px] px-[15px] rounded-xl hover:bg-gray-700 transition self-start"
                >
                  Detalhes
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>

  );
};

export default Portfolio;
