// src/pages/Servicos.tsx
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ServicesHeader from '../components/Services/ServicesHeader';
import ServiceSection from '../components/Services/ServiceSection';
import CallToAction from '../components/Services/CallToAction';
import { motion } from 'framer-motion';

const Servicos: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow mt-16 bg-[#292A2E]">
        <ServicesHeader />
        <div className="container mx-auto px-4 md:px-20 py-12">
          <ServiceSection
            title="Sondagem & Exploração"
            description={`
              <p>Sondagem & Exploração são fundamentais no mundo da mineração, servindo como a primeira etapa na descoberta de depósitos minerais valiosos.</p>
              <br/>
              <p>Na RKE Sondagem e Perfurações LTDA, nos orgulhamos de nossa abordagem meticulosa e baseada em dados para explorar o desconhecido.</p>
              <br/>
              <p>Combinando tecnologia avançada com a expertise de nossa equipe, garantimos que cada projeto seja conduzido com a máxima precisão.</p>        
              <br/>
              <p>Nossa missão é fornecer insights detalhados sobre o potencial mineral de uma área, reduzindo riscos e maximizando o retorno sobre o investimento.</p>
              <br/>
              <p>Escolher nossos serviços de Sondagem & Exploração é optar por um parceiro confiável que entende as nuances e desafios da indústria de mineração.</p>
            `}
            imageSrc="/images/sondagem.png"
            features={[
              'Tecnologia de ponta em sondagem.',
              'Equipe especializada com anos de experiência.',
              'Abordagem baseada em dados para resultados precisos.',
              'Redução de riscos e maximização do ROI.',
              'Insights detalhados sobre potencial mineral.',
            ]}
          />
          <ServiceSection
            title="Perfuração"
            description={`
              <p>A perfuração é uma arte e ciência combinadas, essencial para acessar e avaliar depósitos minerais no subsolo.</p>
              <br/>
              <p>Na RKE Sondagem e Perfurações LTDA, elevamos a perfuração a um novo patamar, utilizando equipamentos de última geração e técnicas avançadas.</p>
              <br/>
              <p>Cada projeto de perfuração é executado com precisão milimétrica, garantindo a coleta de amostras representativas e confiáveis.</p>
              <br/>
              <p>Nossa abordagem é projetada para minimizar interrupções e maximizar a eficiência, tornando-nos o parceiro ideal para suas necessidades de perfuração.</p>
            `}
            imageSrc="/images/perfuracao.png"
            features={[
              'Equipamentos de perfuração de última geração.',
              'Precisão e eficiência em cada projeto.',
              'Técnicas avançadas para coleta de amostras.',
              'Minimização de interrupções e impactos.',
            ]}
            reverse
          />
          <ServiceSection
            title="Relatórios Químicos e Físicos"
            description={`
              <p>Os relatórios químicos e físicos são a espinha dorsal de qualquer projeto de mineração, fornecendo insights cruciais sobre a composição e qualidade dos depósitos minerais.</p>
              <br/>
              <p>Utilizando laboratórios avançados e técnicas de ponta, nossos especialistas examinam cada amostra com rigor, garantindo que você tenha todas as informações necessárias para tomar decisões informadas.</p>
              <br/>
              <p>Nossos relatórios são claros, concisos e projetados para serem facilmente interpretados, tornando-os uma ferramenta valiosa para stakeholders em todos os níveis.</p>
            `}
            imageSrc="/images/analise-quimica.png"
            features={[
              'Análises detalhadas seguindo padrões internacionais.',
              'Laboratórios avançados com equipamentos de ponta.',
              'Especialistas altamente treinados em análises químicas e físicas.',
              'Relatórios claros e de fácil interpretação.',
            ]}
          />
        </div>
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Servicos;
