// src/components/Portfolio/PortfolioHeader.tsx
import React from 'react';
import { motion } from 'framer-motion';

const PortfolioHeader: React.FC = () => {
  return (
    <motion.div
      className="relative h-96 flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: 'url(/images/drilling.png)' }}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h1 className="text-4xl md:text-6xl font-bold text-white">
        Sobre Nós
      </h1>
    </motion.div>
  );
};

export default PortfolioHeader;
