// src/components/Services/ServiceSection.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface ServiceSectionProps {
  title: string;
  description: string;
  imageSrc: string;
  features: string[];
  reverse?: boolean;
}

const ServiceSection: React.FC<ServiceSectionProps> = ({ title, description, imageSrc, features, reverse = false }) => {
  return (
    <div className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center my-12`}>
      <motion.div
        className="md:w-1/2 w-full h-64 md:h-96 bg-cover bg-center rounded-xl"
        style={{ backgroundImage: `url(${imageSrc})` }}
        initial={{ opacity: 0, x: reverse ? 100 : -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      />
      <motion.div
        className="md:w-1/2 w-full p-6"
        initial={{ opacity: 0, x: reverse ? -100 : 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-semibold text-[#CFB354] mb-4">{title}</h2>
        <div
          className="text-white mb-4"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <ul className="list-disc list-inside text-white">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default ServiceSection;
