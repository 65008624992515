// src/components/Sobre/MetricCard.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface MetricCardProps {
  value: string;
  description: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ value, description }) => {
  return (
    <motion.div
      className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <h3 className="text-3xl font-bold text-[#19154E]">{value}</h3>
      <p className="mt-2 text-center text-gray-700">{description}</p>
    </motion.div>
  );
};

export default MetricCard;
