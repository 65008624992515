// src/components/Hero/Hero.tsx
import React from 'react';
import { motion } from 'framer-motion';

const Hero: React.FC = () => {
  return (
    <section
      className="w-full h-[70vh] md:h-[60vh] bg-cover bg-center flex items-center justify-center md:justify-start"
      style={{
        backgroundImage: 'url(/images/heroimage.png)',
      }}
    >
      <div className="p-8 rounded-md text-left max-w-2xl mx-auto md:mx-0 md:ml-16">
        <motion.h1
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-5xl md:text-6xl font-bold text-white"
        >
          RKE Sondagem e Perfurações LTDA
        </motion.h1>
        <motion.p
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="mt-6 text-2xl md:text-3xl text-yellow-400"
        >
          Bem-vindo à RKE Sondagem e Perfurações LTDA, seus especialistas de confiança em sondagem e perfuração de mineração. Mergulhe em nosso mundo de precisão e exatidão.
        </motion.p>
      </div>
    </section>
  );
};

export default Hero;
